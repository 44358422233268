@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?bmxsoc');
  src:  url('./fonts/icomoon.eot?bmxsoc#iefix') format('embedded-opentype'),
  url('./fonts/icomoon.ttf?bmxsoc') format('truetype'),
  url('./fonts/icomoon.woff?bmxsoc') format('woff'),
  url('./fonts/icomoon.svg?bmxsoc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.univey-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.univey-icon.four-dots::before {
  content: "\e900";
}

.univey-icon.six-dots::before {
  content: "\e901";
}


/* HEIGHT UTILITIES */
.h-full {
  height: 100%;
}

.min-h-fv {
  min-height: 100vh;
}

/* MARGIN UTILITIES */
.ph-2.ph-2.ph-2.ph-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
